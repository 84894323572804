import React, { useState } from "react";

import PropTypes from "prop-types";

import "./MyChallengesGridView.scss";
import MyChallengesDetails from "./MyChallengesDetails";
import ChallengesDate from "./ChallengesDate";

const MyChallengesGridView = (props) => {
  const currentDayList = props.myChallengeList.map((item) => item.day);
  const currentDay = Math.max(...currentDayList);

  const [activeIndex, setActiveIndex] = useState(currentDay - 1);

  const viewCurrentDetails = (index) => {
    setActiveIndex(index);
  };
  const items = Array.from({ length: 90 });

  const GridCalendar = (currentDate) => {
    return items.map((_, index) => (
      <div key={index} className="calendar-item">
        <ChallengesDate
          key={index}
          day={index + 1}
          active={activeIndex === index}
          currentDate={currentDate === index + 1}
          clickFunc={() => viewCurrentDetails(index)}
          completed={index + 1 < currentDate}
          disabled={index + 1 > currentDate}
        />
      </div>
    ));
  };

  return (
    <div className="my-challenges-grid-wrap">
      <div className="my-challenges-grid-left">
        <div className="my-challenges-grid-table">
          <div className="grid-table-inner">
            <div className="grid-table-top">
              <div className="grid-table-top-left">
                <span>Days</span>
              </div>
              <div className="grid-table-top-right">
                <span>Day 1</span>
                <span>Day 2</span>
                <span>Day 3</span>
                <span>Day 4</span>
                <span>Day 5</span>
                <span>Day 6</span>
                <span>Day 7</span>
                <span>Day 8</span>
                <span>Day 9</span>
                <span>Day 10</span>
              </div>
            </div>
            <div className="grid-table-content">
              <div className="grid-table-content-left">
                <span className="start">Start 🏁</span>
                <span>+10 days</span>
                <span>+20 days</span>
                <span>+30 days</span>
                <span>+40 days</span>
                <span>+50 days</span>
                <span>+60 days</span>
                <span>+70 days</span>
                <span>+80 days</span>
              </div>
              <div className="grid-table-content-right">
                {GridCalendar(currentDay)}
              </div>
            </div>
            <div className="grid-table-bottom">
              <span className="win-text">Win 🏅</span>
            </div>
          </div>
          {/* <span>Days:</span> */}
        </div>
      </div>
      {activeIndex >= 0 && (
        <div className="my-challenges-grid-right">
          {/* need to pass other data as well, just now passing day value as a test */}
          <MyChallengesDetails
            activeIndexDetails={props.myChallengeList[activeIndex]}
            submitButtonFunc={props.submitButtonFunc}
            day={activeIndex + 1}
          />
        </div>
      )}
    </div>
  );
};

MyChallengesGridView.defaultProps = {
  // rootClassName: '',
  // text: 'default text',
  myChallengeList: [],
};

MyChallengesGridView.propTypes = {
  submitButtonFunc: PropTypes.func,
  myChallengeList: PropTypes.array,
};

export default MyChallengesGridView;
