import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./login.scss";

const ChoosePassword = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordSame, setPasswordSame] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordType, setPasswordType] = useState(true);

    const [validate, setValidate] = useState({
        hasLow: false,
        hasCap: false,
        hasNumber: false,
        has8digit: false
    });


    const strength = Object.values(validate).reduce((a, item) => a + item, 0);
    const feedback = {
        1: "Use 8 or more characters with a mix of letters, numbers & symbols",
        2: "Mix numbers & symbols with text to make it stronger",
        3: "Mix numbers & symbols with text to make it stronger",
        4: "Perfect! Now it's an extra strong password"
    }[strength];

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
        validatePassword(e.target.value);
        if (e.target.value === confirmPassword && strength === 4) {
            setPasswordSame(true)
        } else {
            setPasswordSame(false)
        }
    };

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        if (password === e.target.value && strength === 4) {
            setPasswordSame(true)
        } else {
            setPasswordSame(false)
        }
    };

    const validatePassword = (password) => {
        if (password.match(/\d+/g)) {
            setValidate((o) => ({ ...o, hasNumber: true }));
        } else {
            setValidate((o) => ({ ...o, hasNumber: false }));
        }

        if (password.match(/[A-Z]+/g)) {
            setValidate((o) => ({ ...o, hasCap: true }));
        } else {
            setValidate((o) => ({ ...o, hasCap: false }));
        }

        if (password.match(/[a-z]+/g)) {
            setValidate((o) => ({ ...o, hasLow: true }));
        } else {
            setValidate((o) => ({ ...o, hasLow: false }));
        }

        if (password.length > 7) {
            setValidate((o) => ({ ...o, has8digit: true }));
        } else {
            setValidate((o) => ({ ...o, has8digit: false }));
        }
    };

    // Handler for the next button click
    const handleContinue = async () => {
        setLoading(true)
        // add the funtion here
    };

    const togglePasswordView = () => {
        setPasswordType(!passwordType)
    }

    return (
        <div className="login-container">
            <Helmet>
                <title>Choose a password</title>
                <meta property="og:title" content="Choose a password - 90 D" />
            </Helmet>
            <Navbar />
            <div className="container">
                <div className="login-wrap">
                    <h1>Choose a password</h1>
                    <p>Email: user@mail.com</p>
                    <div className="login-form">
                        <div className="email-ip-wrap pass-ip-wrap">
                            <input
                                type={passwordType ? 'password' : 'text'}
                                className={`login-ip pass-ip ${password.length && password.length < 8 ? 'error' : ''}`}
                                value={password}
                                onChange={(e) => handleChangePassword(e)}
                                onKeyDown={(e) => handleChangePassword(e)}
                                placeholder="password"
                            />
                            {strength > 0 ? (
                                <div
                                    hidden={password.length === 0}
                                    className={`password-strength strength-${strength}`}
                                >
                                    <span className={`pass-meter pm-${strength}`} ></span>
                                    <span className={`pass-meter pm-${strength}`} ></span>
                                    <span className={`pass-meter pm-${strength}`} ></span>
                                    <span className={`pass-meter pm-${strength}`} ></span>
                                </div>
                            ) : null}
                        </div>

                        <div className="feedback" hidden={password.length === 0}>
                            {feedback}
                        </div>

                        <div className="email-ip-wrap pass-ip-wrap">
                            <input
                                type={passwordType ? 'password' : 'text'}
                                className={`login-ip pass-ip ${confirmPassword.length > 0 && !passwordSame ? 'error' : ''}`}
                                value={confirmPassword}
                                onChange={(e) => handleConfirmPassword(e)}
                                onKeyDown={(e) => handleConfirmPassword(e)}
                                placeholder="confirm password"
                            />
                            <div className="password-strength" onClick={togglePasswordView}>
                                <img src="assets/eye-icon.svg" title="toggle view" alt="view" />
                            </div>
                        </div>
                        {confirmPassword.length > 0 && !passwordSame && (
                            <span className="error">Passwords do not match</span>
                        )}
                        <div className="login-form-buttons">
                            <button type="button"
                                className={`${loading ? 'btn-loading' : ''} login-email-btn`}
                                onClick={handleContinue}
                                disabled={!passwordSame}
                            >Next
                            </button>
                        </div>
                        <div className="login-form-bottom">
                            By using 90D you agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                        </div>
                    </div>


                </div>
                <FooterMinimal />
            </div>
        </div>
    );
}

export default ChoosePassword;
