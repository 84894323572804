import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import './SurveyOption.scss'

const SurveyOptionOther = (props) => {




    return (
        <div className='survey-option-item'>
            <button type='button'
                onClick={props.clickFunc}
                className={props.selectedItem === props.otherItem ? 'selected' : ''}
            >{props.otherItem}</button>
            {props.showOtherInput && (
                <div className='other-role-ip'>
                    <input tabIndex={0} type='text' placeholder={props.otherItem === 'Others (specify)' ? 'please specify' : props.otherItem} name="custom_role" onInput={props.onInputFunc} />
                    <button onClick={props.saveNewInputFunc} type='button' disabled={props.otherItem === 'Others (specify)' ? 'disabled' : false}>
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5L5 9L13 1" stroke="#fff" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>

            )}
        </div>
    )
}

SurveyOptionOther.propTypes = {
    selectedItem: PropTypes.string,
    clickFunc: PropTypes.func,
    saveNewInputFunc: PropTypes.func,
    onInputFunc: PropTypes.func,
    showOtherInput: PropTypes.bool,
    otherItem: PropTypes.string,
}

export default SurveyOptionOther