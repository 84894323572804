import React from 'react'

import PropTypes from 'prop-types'

import './why-points.css'

const WhyPoints = (props) => {
  return (
    <li className={`why-points ${props.rootClassName} `}>
      <span className="why-points-text">{props.text}</span>
    </li>
  )
}

WhyPoints.defaultProps = {
  rootClassName: '',
  text: 'default text',
}

WhyPoints.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default WhyPoints
