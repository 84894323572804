import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./login.scss";
import { useSelector } from "react-redux";
import { signInWithGoogle, signInWithEmailLinkApp } from "../api/auth";
import { isEmailValid } from "../utils/isEmailValid";

const FirstLastName = (props) => {
    // State variable to store the names
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [enableBtn, setEnableBtn] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [loading, setLoading] = useState(false);

    const isValid = (item) => {
        if (item === '') {
            return false
        }
        return true
    }

    // Handler for name input changes
    const handleNameChange = (event, type) => {
        if (type === 'first') {
            setFirstName(event.target.value);
            setEnableBtn(true);

        } else {
            setLastName(event.target.value);
            setEnableBtn(true);

        }

    };



    // Handler for the continue button click
    const handleContinue = () => {
        // Check is Email Valid or not
        if (!isValid(firstName)) {
            setFirstNameError(true)
            return;
        }
        if (!isValid(lastName)) {
            setLastNameError(true)
            return;
        }
        setFirstNameError(false)
        setLastNameError(false)
        setLoading(true)
    };

    return (
        <div className="login-container">
            <Helmet>
                <title>Your name - 90 D</title>
                <meta property="og:title" content="Your Name - 90 D" />
            </Helmet>
            <Navbar />
            <div className="container">
                <div className="login-wrap">
                    <h1>What's your name?</h1>
                    <p>Your real name, please. We'll use it in our communications.</p>
                    <div className="login-form">
                        <div className="name-field-wrap">
                            <div className="email-ip-wrap">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    placeholder="First name"
                                    className={`login-ip ${firstNameError ? 'error' : ''}`}
                                    value={firstName} // Set the value of the input to the state variable
                                    onChange={(e) => handleNameChange(e, 'first')} // Set the onChange handler
                                    onKeyDown={(e) => handleNameChange(e, 'first')} // Set the onChange handler
                                    required
                                />
                                {firstNameError && (
                                    <span className="error">Valid name required</span>
                                )}
                            </div>
                            <div className="email-ip-wrap">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    placeholder="Last name"
                                    className={`login-ip ${lastNameError ? 'error' : ''}`}
                                    value={lastName} // Set the value of the input to the state variable
                                    onChange={(e) => handleNameChange(e, 'last')} // Set the onChange handler
                                    onKeyDown={(e) => handleNameChange(e, 'last')} // Set the onChange handler
                                    required
                                />
                                {lastNameError && (
                                    <span className="error">Valid name required</span>
                                )}
                            </div>
                        </div>

                        <div className="remember-user">
                            <label><input type="checkbox" /><span>Remember this account</span></label>
                        </div>

                        <div className="login-form-buttons">
                            <button type="button"
                                className={`${loading ? 'btn-loading' : ''} login-email-btn`}
                                onClick={handleContinue}
                                disabled={!enableBtn}
                            >Create an account
                            </button>
                        </div>
                        <div className="login-form-bottom">
                            By using 90D you agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMinimal />
        </div>
    );
};

export default FirstLastName;
