// import firebase from 'firebase/app';
// import 'firebase/firestore'; // If you're using Firestore
// import 'firebase/auth'; // If you're using Firebase Authentication

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCoXKLzVlLn9-LO2k8QdWF8dteXb4tIs_Y",
    authDomain: "the-90-days-of-design.firebaseapp.com",
    projectId: "the-90-days-of-design",
    storageBucket: "the-90-days-of-design.appspot.com",
    messagingSenderId: "604469925444",
    appId: "1:604469925444:web:18180f67294fe37c690e11",
    measurementId: "G-5FDXJ07ENQ"
  };

// firebase.initializeApp(firebaseConfig);
const firebase = initializeApp(firebaseConfig);
export const auth = getAuth(firebase)
export default firebase;
