import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import SurveyOption from './SurveyOption'
import SurveyOptionOther from './SurveyOptionOther'

// import './SurveyForm.scss'

const SurveyForm = (props) => {
    return (

        <div className='survey-form-item'>
            <div className='survey-form-question'>{props.title}</div>
            <div className='survey-form-options'>
                {props.options.map((option) => (
                    <SurveyOption key={option} btnText={option} selectedItem={props.selectedItem} clickFunc={() => props.changeSelected(option)} />
                ))}
                {props.showOtherOption && (
                    <SurveyOptionOther selectedItem={props.selectedItem} clickFunc={props.otherOptionClickFunc} showOtherInput={props.showOtherInput} otherItem={props.otherDefaultText} onInputFunc={props.otherInputFunc} saveNewInputFunc={props.saveInputFunc} />
                )}
            </div>
        </div>

    )
}

SurveyForm.propTypes = {
    title: PropTypes.string,
    selectedItem: PropTypes.string,
    showOtherInput: PropTypes.bool,
    changeSelected: PropTypes.func,
    otherOptionClickFunc: PropTypes.func,
    otherInputFunc: PropTypes.func,
    saveInputFunc: PropTypes.func,
    otherDefaultText: PropTypes.string,
    options: PropTypes.array,
    showOtherOption: PropTypes.bool,
}

export default SurveyForm