import { getApi, postApi } from "../base";

export const getUserChallenge = () => {
  const res = getApi(`/challenge`);
  return res;
};

export const initUserChallenge = (payload) => {
  const res = postApi(`/challenge`, payload);
  return res;
};

// Get all my-challenges list
export const getCurrentSubmitChallenege = () => {
  const res = getApi(`/submit-challenge`);
  return res;
};

// Post all my-challenges list
export const postCurrentChallenege = (payload) => {
  const res = postApi(`/submit-challenge`, payload);
  return res;
};

// Get all my-challenges list
export const getMyChallenegeList = () => {
  const res = getApi(`/my-challenge`);
  return res;
};
