export const TOAST_ERROR_MESSAGE = {
  toast: {
    message: "Something bad happened.",
    type: "ERROR",
  },
};

export const TOAST_EMAIL_SENT_MESSAGE = {
  toast: {
    message: "Email send for verification",
    type: "SUCCESS",
  },
};
