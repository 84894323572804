import React from "react";
import PropTypes from 'prop-types'

import './ChallengeStatus.scss'


const ChallengeStatus = (props) => {
    const getStatusIcon = (type) => {
        if (type.toLowerCase() === 'completed') {
            return <img src='/assets/icon-complete.png' alt="complete" />
        }
        if (type.toLowerCase() === 'ongoing') {
            return <img src='/assets/icon-ongoing.png' alt="ongoing" />

        }
        return ''
    }

    const statusInfo = props.status.toLowerCase() === 'ongoing' ?
        <i>Started on: {props.startDate}</i> :
        <i>{props.startDate} &mdash; {props.endDate}</i>;

    return (
        <div className='challenge-status-wrap'>
            <div className='challenge-status-icon'>{getStatusIcon(props.status)}</div>
            <div className='challenge-status'>
                <span>{props.status}</span>
                {statusInfo}
            </div>
        </div>
    )
}

ChallengeStatus.defaultProps = {

}

ChallengeStatus.propTypes = {
    status: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
}

export default ChallengeStatus