import React, { useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./navbar.scss";
import { useSelector } from "react-redux";
import AudioPlayer from "./AudioPlayer";

const Navbar = (props) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <div className={`navbar-container ${props.rootClassName} `}>
      <div className="container">
        <div className="navbar-wrap">
          <Link to="/" className="navbar-navlink">
            <img
              alt="90d"
              src="/external/unioni217-xgu9.svg"
              className="navbar-logo"
            />
          </Link>
          <div className="navbar-right-elements">
            <Link to="/" className="navbar-navlink">
              How it works
            </Link>
            <Link to="/pricing" className="navbar-navlink">
              Pricing
            </Link>
            <div className="navbar-twitter-follow"
              onClick={() =>
                window.open("https://appshots.design/", "_blank")
              }>
              <img
                alt="image1I217"
                src="/icons/twitter-nav-icon-200h.png"
                className="navbar-image1"
                width={18}
              />
              <span>Follow Appshots</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
              >
                <path
                  d="M8 1L1.5 7.5"
                  stroke="#ABA286"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.5 1H8V5.5"
                  stroke="#ABA286"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <a href="#" className="navbar-navlink">
              <img
                alt="lang"
                src={props.image_src1}
              />
            </a>
            <span className="navbar-text1">
              <AudioPlayer />
            </span>
            {!isAuthenticated ? (
              <>
                <Link to="/login" className="navbar-navlink navbar-btn">
                  Log in
                </Link>
                <Link to="/login" className="navbar-navlink navbar-btn navbar-btn-invert">
                  Try for free
                </Link>
              </>
            ) : <>
              <button className="user-button" onClick={() => setShowUserMenu(!showUserMenu)} type="button"><img src='/assets/user.png' width={36} height={36} alt='user' /></button>
              {showUserMenu && (
                <ul className="user-dropdown">
                  <li><a href="#">My Account</a></li>
                  <li><a href="#">Logout</a></li>
                </ul>
              )}
            </>}
          </div>
        </div>
      </div>
    </div >
  );
};

Navbar.defaultProps = {
  image_src1: "/icons/localization-icon.svg",
  rootClassName: "",
  image_alt: "image",
};

Navbar.propTypes = {
  image_src1: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
};

export default Navbar;
