import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./ImageUploader.scss";

export default function ImageUploader({
  getPublicUrl = () => {},
  screen_name = "",
  screen_url = "",
  clearPublicUrl = () => {},
}) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append("file", file);
      // Base url
      const baseUrl =
        "https://api.bytescale.com/v2/accounts/FW25bq3/uploads/form_data?folderPath=/90d-bucket&folderPathVariablesEnabled=true";
      // uploading
      const xhr = new XMLHttpRequest();
      xhr.open("POST", baseUrl);
      xhr.setRequestHeader(
        "Authorization",
        "Bearer public_FW25bq3ExNXqRPzvvkufWUEiitGA"
      );

      xhr.onload = function () {
        if (xhr.status === 200) {
          // TODO: TOAST
          const { files } = JSON.parse(xhr.responseText);
          getPublicUrl(files[0], screen_name);
        } else {
        }
      };
      xhr.onerror = function () {
        // Handle network errors
      };

      xhr.send(formData);
    },
    [getPublicUrl, screen_name]
  );

  const removeFile = () => {
    clearPublicUrl(screen_name);
  };

  const allowedTypes = {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: allowedTypes,
  });

  return (
    <div className="image-uploader">
      <div
        {...getRootProps()}
        className={`upload-block ${isDragActive ? "drag-active" : ""}`}
      >
        <input {...getInputProps()} />
        <div className="upload-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="46"
            viewBox="0 0 64 46"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M34.6677 26.6672V37.3338H29.3343V26.6672H18.6677L32.001 13.3338L45.3343 26.6672H34.6677ZM52.992 18.8693C51.1707 8.12 41.776 0 30.6667 0C19.1893 0 9.664 8.52267 8.216 19.7227C3.29333 21.7627 0 26.568 0 32C0 39.352 5.98133 45.3333 13.3333 45.3333H50.6667C58.0187 45.3333 64 39.352 64 32C64 25.44 59.24 19.9707 52.992 18.8693Z"
              fill="#D1D1D1"
            />
          </svg>
          <div className="upload-text">
            <span>Select file</span> / drag &amp; drop
          </div>
        </div>
      </div>

      {screen_url && (
        <div className="preview-upload" key={screen_url}>
          <img src={screen_url} alt="preview" />
          <button
            title="remove"
            type="button"
            className="remove-btn"
            onClick={removeFile}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M15 1L1 15"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 15L1 1"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}
