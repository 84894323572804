import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import ChallengesDate from './ChallengesDate'

import './ChallengesHeaderMeta.scss'

const ChallengesHeaderMeta = (props) => {
    return (
        <div className='challenges-date-breadcrumbs'>
            <ChallengesDate day={props.day} />
            {props.breadcrumbsData && props.breadcrumbsData.length && <ul className='challenged-breadcrumbs'>
                {props.breadcrumbsData.map((item) => (
                    <li key={item.id}>
                        <Link to={item.link}>{item.text}</Link>
                    </li>
                ))}
            </ul>}
        </div>
    )
}

ChallengesHeaderMeta.defaultProps = {
    day: 1,
    breadcrumbsData: null,
}

ChallengesHeaderMeta.propTypes = {
    day: PropTypes.number,
    breadcrumbsData: PropTypes.array,
}

export default ChallengesHeaderMeta