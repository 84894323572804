import React from "react";
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";

import "./style.css";
import UserSurvey from "./views/user-survey";
import ChallengeSubmit from "./views/challenge-submit";
import Challenge from "./views/challenge";
import Home from "./views/home";
import Login from "./views/login";
import Processing from "./views/processing";
import HowItWorks from "./views/how-it-works";
import NotFound from "./views/not-found";
import MyChallenges from "./views/my-challenges";
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from "./utils/PrivateRoute";
import PreAuthRoute from "./utils/PreAuthRoute";
import Signup from "./views/signup";
import ChoosePassword from "./views/ChoosePassword";
import FirstLastName from "./views/FirstLastName";
import UserSurveyNew from "./views/user-survey-new";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <Router>
          <ScrollToTop />
          <Switch>
            <PrivateRoute component={UserSurvey} exact path="/survey" />
            <PrivateRoute
              component={ChallengeSubmit}
              exact
              path="/submitChallenge"
            />
            <PrivateRoute component={MyChallenges} exact path="/my-challenge" />
            <PrivateRoute component={Challenge} exact path="/challenge" />
            <PreAuthRoute component={HowItWorks} exact path="/" />
            <PreAuthRoute component={Login} exact path="/login" />
            <PreAuthRoute component={Signup} exact path="/signup" />
            <PreAuthRoute component={ChoosePassword} exact path="/choose-password" />
            <PreAuthRoute component={FirstLastName} exact path="/your-name" />
            <Route component={Processing} exact path="/processing" />
            <Route component={UserSurveyNew} exact path="/user-survey-new" />
            <Route component={NotFound} path="**" />
            <Redirect to="**" />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <>
    <App />
    <ToastContainer />
  </>
);
