import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import FooterMinimal from '../components/footer-minimal'
import './processing.css'
import { confirmSignInWithEmailLink } from '../api/auth'

const Processing = (props) => {
  
  useEffect(() => {
    confirmSignInWithEmailLink();
  })

  return (
    <div className="processing-container">
      <Helmet>
        <title>Processing - 90 D</title>
        <meta property="og:title" content="Processing - 90 D" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name8"></Navbar>
      <FooterMinimal rootClassName="footer-minimal-root-class-name8"></FooterMinimal>
      <div className="processing-main-content">
        <img
          alt="Rectangle232171"
          src="/assets/running-galloping-600h.gif"
          className="processing-rectangle23"
        />
        <span className="processing-text">
          <span>Personalizing...</span>
          <br></br>
        </span>
      </div>
    </div>
  )
}

export default Processing
