import { auth } from "../firebase";
import {
  getIdToken,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import store from "../store";
import apiClient from "./apiClient";
import { loginUser, logoutUser } from "../store/reducers/authSlice";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import {
  TOAST_EMAIL_SENT_MESSAGE,
  TOAST_ERROR_MESSAGE,
} from "../utils/message";

// API for signInWithGoogle
export const signInWithGoogle = (history) => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      getIdToken(result.user)
        .then((idToken) => firebaseAuthValidate(idToken, history))
        .catch((error) => {});
    })
    .catch((error) => {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    });
};

// API for authentication validate
const firebaseAuthValidate = (idToken, history) => {
  const bodyFormData = new FormData();
  bodyFormData.append("id_token", idToken);
  apiClient
    .post("firebase_auth_validate/", bodyFormData)
    .then((result) => {
      const responseData = result.data;
      store.dispatch(loginUser(responseData));
      navigateToScreenOrToast(result.data, history);
    })
    .catch(() => {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    });
};

// API for password less login using email link
export const signInWithEmailLinkApp = async (email) => {
  try {
    const actionCodeSettings = {
      url: window.location.origin + "/processing", // This is your 'processing' page URL.
      handleCodeInApp: true, // This must be true.
    };

    console.log(email);
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    navigateToScreenOrToast(TOAST_EMAIL_SENT_MESSAGE);
    console.log("email sent");
    // The link was successfully sent. Inform the user.
    // Save email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem("emailForSignIn", email);
  } catch (error) {
    // Handle Errors here.
    throw error;
  }
};

// API for confirming the sign-in with email link
export const confirmSignInWithEmailLink = async () => {
  try {
    console.log("confirming");
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    const windowLocation = window.location;
    if (isSignInWithEmailLink(auth, windowLocation.href)) {
      // The client SDK will parse the code from the link for you.
      console.log("signing in");
      const result = await signInWithEmailLink(
        auth,
        email,
        windowLocation.href
      );
      // Clear email from storage.
      window.localStorage.removeItem("emailForSignIn");
      getIdToken(result.user)
        .then((idToken) => firebaseAuthValidate(idToken))
        .catch((error) => {});
    }
  } catch (error) {
    // redrect to login page
    console.log(error);
    window.location.href = "/login";
  }
};

// API for signOut from google and clear persisted data
export const signOutFromGoogle = () => {
  signOut(auth)
    .then(() => {
      store.dispatch(logoutUser());
    })
    .catch((error) => {});
};
