import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./user-survey.scss";
import SurveyForm from "../components/SurveyForm";
import Button from "../components/Button";
import { getUserSurvey, postUserSurvey } from "../api/routes/survey";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import { TOAST_ERROR_MESSAGE } from "../utils/message";

const UserSurvey = (props) => {
  const history = useHistory();
  const [question, setQuestion] = useState([]);

  useEffect(() => {
    fetchUserSurvey();
  }, []);

  const fetchUserSurvey = async () => {
    try {
      const response = await getUserSurvey();
      if (response && response.data) {
        setQuestion(response.data.question);
        navigateToScreenOrToast(response.data, history);
      }
    } catch (error) {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    }
  };

  const submitUserSurvey = async () => {
    // Selected Item from questions array
    const options = question?.survey_question_options.find(
      (item) => item?.radio_options === selectedItem
    );
    // Find its difficulty
    // TODO: logic for textinput difficulty
    // If textinput --> medium difficulty
    const difficulty_level = !!options ? options?.difficulty_level : "medium";
    const payload = {
      question: question?.id,
      response_text: selectedItem,
      difficulty_level: difficulty_level,
    };
    try {
      const response = await postUserSurvey(payload);
      if (response && response.data) {
        navigateToScreenOrToast(response.data, history);
      }
    } catch (error) {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    }
  };

  const [disableContinue, setDisableContinue] = useState(true);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [surveyConfirmPage, setSurveyConfirmPage] = useState(false);

  const [surveyFormActive, setSurveyFormActive] = useState(1);

  const defaultOtherText = "Others (specify)";

  const [otherItemRole, setOtherItemRole] = useState(defaultOtherText);
  const [otherItemProfession, setOtherItemProfession] =
    useState(defaultOtherText);
  const [otherItemExperience, setOtherItemExperience] =
    useState(defaultOtherText);

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemRole, setSelectedItemRole] = useState("");
  const [selectedItemProfession, setSelectedItemProfession] = useState("");
  const [selectedItemExperience, setSelectedItemExperience] = useState("");

  const changeSelected = (item) => {
    if (surveyFormActive === 1) {
      setSelectedItemRole(item);
    }
    if (surveyFormActive === 2) {
      setSelectedItemProfession(item);
    }
    if (surveyFormActive === 3) {
      setSelectedItemExperience(item);
    }
    setSelectedItem(item);
    setDisableContinue(false);
    setShowOtherInput(false);
  };

  const showOtherItem = () => {
    setShowOtherInput(true);
    setSelectedItem("");
    setDisableContinue(true);
    setTimeout(() => {
      document.querySelector(".other-role-ip input").focus();
    }, 100);
  };

  const saveOtherItemRole = () => {
    if (otherItemRole !== "") {
      setShowOtherInput(false);
      changeSelected(otherItemRole);
    } else {
      alert("cannot be blank!");
      setOtherItemRole(defaultOtherText);
    }
  };

  const saveOtherItemProfession = () => {
    if (otherItemProfession !== "") {
      setShowOtherInput(false);
      changeSelected(otherItemProfession);
    } else {
      alert("cannot be blank!");
      setOtherItemProfession(defaultOtherText);
    }
  };

  const saveOtherItemExperience = () => {
    if (otherItemExperience !== "") {
      setShowOtherInput(false);
      changeSelected(otherItemExperience);
    } else {
      alert("cannot be blank!");
      setOtherItemExperience(defaultOtherText);
    }
  };

  const continueBtnClick = () => {
    // if (surveyFormActive < 3) {
    //   // set the maximum survey form item here
    //   setSurveyFormActive(surveyFormActive + 1);
    // } else {
    //   setSurveyConfirmPage(true);
    // }
    // TODO: POST Response
    submitUserSurvey();
  };

  const editOption = (type) => {
    setSurveyConfirmPage(false);
    if (type === "role") {
      setSurveyFormActive(1);
    }
    if (type === "profession") {
      setSurveyFormActive(2);
    }
    if (type === "experience") {
      setSurveyFormActive(3);
    }
  };

  return (
    <>
      <Helmet>
        <title>UserSurvey - 90 D</title>
        <meta property="og:title" content="UserSurvey - 90 D" />
      </Helmet>
      <Navbar />
      <div className="container">
        <div className="user-survey-wrap">
          <div className="user-survey-right">
            <img src="assets/survey-right.png" alt="survey" />
          </div>
          <div className="user-survey-left">
            {!surveyConfirmPage ? (
              <>
                <div className="user-survey-top">
                  <h2>Let's know you better</h2>
                  <p>
                    Answering this help us to set the challenge matching with
                    your level of design expertise
                  </p>
                </div>
                <div className="survey-form-wrap">
                  {surveyFormActive === 1 && (
                    <SurveyForm
                      key={1}
                      title={
                        question?.question_text
                          ? `1. ${question?.question_text}`
                          : ""
                      }
                      options={
                        question?.survey_question_options
                          ? question?.survey_question_options.map(
                              (item) => item.radio_options
                            )
                          : []
                      }
                      showOtherOption
                      selectedItem={selectedItemRole}
                      changeSelected={changeSelected}
                      otherOptionClickFunc={showOtherItem}
                      showOtherInput={showOtherInput}
                      otherDefaultText={otherItemRole}
                      otherInputFunc={(e) => setOtherItemRole(e.target.value)}
                      saveInputFunc={saveOtherItemRole}
                    />
                  )}

                  {surveyFormActive === 2 && (
                    <SurveyForm
                      key={2}
                      title={`2. What's your specification in design profession`}
                      options={[
                        "Graphic Design",
                        "UI Design",
                        "UX Design",
                        "Product Design",
                      ]}
                      showOtherOption
                      selectedItem={selectedItemProfession}
                      changeSelected={changeSelected}
                      otherOptionClickFunc={showOtherItem}
                      showOtherInput={showOtherInput}
                      otherDefaultText={otherItemProfession}
                      otherInputFunc={(e) =>
                        setOtherItemProfession(e.target.value)
                      }
                      saveInputFunc={saveOtherItemProfession}
                    />
                  )}

                  {surveyFormActive === 3 && (
                    <SurveyForm
                      key={3}
                      title={`3. What's your experience level?`}
                      options={[
                        "Junior (0-1 years)",
                        "Mid-level (1-3 years)",
                        "Senior (3-6 years)",
                        "Lead (Above 6 years)",
                      ]}
                      showOtherOption
                      selectedItem={selectedItemExperience}
                      changeSelected={changeSelected}
                      otherOptionClickFunc={showOtherItem}
                      showOtherInput={showOtherInput}
                      otherDefaultText={otherItemExperience}
                      otherInputFunc={(e) =>
                        setOtherItemExperience(e.target.value)
                      }
                      saveInputFunc={saveOtherItemExperience}
                    />
                  )}

                  <div className="survey-form-btn">
                    <Button
                      btnText="Continue"
                      disabled={disableContinue ? true : false}
                      clickFunc={continueBtnClick}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="user-survey-top">
                  <h2>Let's confirm your inputs</h2>
                </div>
                <div className="survey-form-wrap">
                  <ul className="survey-confirm-data">
                    <li>
                      <span>{selectedItemRole}</span>
                      <button type="button" onClick={() => editOption("role")}>
                        (Edit)
                      </button>
                    </li>
                    <li>
                      <span>{selectedItemProfession}</span>
                      <button
                        type="button"
                        onClick={() => editOption("profession")}
                      >
                        (Edit)
                      </button>
                    </li>
                    <li>
                      <span>{selectedItemExperience}</span>
                      <button
                        type="button"
                        onClick={() => editOption("experience")}
                      >
                        (Edit)
                      </button>
                    </li>
                  </ul>

                  <div className="survey-form-btn">
                    <Button
                      btnText="Confirm & Proceed"
                      clickFunc={() => history.push("/challenge")}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <FooterMinimal />
    </>
  );
};

export default UserSurvey;
