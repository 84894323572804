import React, { useState, useEffect } from 'react';

const AudioPlayer = () => {
    const [audioContext, setAudioContext] = useState(null);

    const createAudioContext = () => {
        setAudioContext(new (window.AudioContext || window.webkitAudioContext)());
    };

    const playPauseTrack = () => {
        if (!audioContext) {
            createAudioContext();
        }
        soundcloudPlayer.contentWindow.postMessage('{"method":"toggle"}', '*');
    };

    return (
        <div>
            <iframe
                id="soundcloudPlayer"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                style={{ opacity: 0, pointerEvents: 'none', position: 'absolute' }}
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/342047810&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;show_teaser=false&amp;visual=false"
            ></iframe>
            <button id="playPauseButton" onClick={playPauseTrack}>
                Toggle Music
            </button>
        </div>
    );
};

export default AudioPlayer;
