import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './ChallengesDate.scss'


const ChallengesDate = (props) => {
    return (
        <div className={`challenges-date ${props.currentDate && 'current'} ${props.completed && 'completed'} ${props.disabled && 'disabled'} ${props.active && 'active'}`} onClick={props.clickFunc}>
            <span className='day-text'>day</span>
            <span className='day-number'>{props.day}</span>
        </div>
    )
}

ChallengesDate.defaultProps = {
    day: 1,
    currentDate: false,
    completed: false,
    disabled: false,
    active: false,
}

ChallengesDate.propTypes = {
    day: PropTypes.number,
    currentDate: PropTypes.bool,
    completed: PropTypes.bool,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    clickFunc: PropTypes.func,
}

export default ChallengesDate