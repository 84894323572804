import React from 'react'

import PropTypes from 'prop-types'

import './hero-feature-item.css'

const HeroFeatureItem = (props) => {
  return (
    <div className="hero-feature-item-group1771">
      <div className="hero-feature-item-group1769">
        <img
          alt={props.title}
          src={props.icon}
          className="hero-feature-item-image13"
        />
        <span className="hero-feature-item-text">{props.title}</span>
      </div>
    </div>
  )
}

HeroFeatureItem.defaultProps = {
  title: 'Feature Item Title',
  icon: '/assets/hero-icon-1.svg',
}

HeroFeatureItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default HeroFeatureItem
