import React from "react";
import PropTypes from 'prop-types'

import './EffectivenessScore.scss'

const EffectivenessScore = (props) => {

    const items = Array.from({ length: 4 });
    return (
        <ul className={`effectiveness-meter effective-score-${props.score}`}>
            {items.map((_, index) => (
                <li key={index} className={index < props.score ? 'filled' : ''}></li>
            ))
            }
        </ul>
    )
}

EffectivenessScore.defaultProps = {

}

EffectivenessScore.propTypes = {
    score: PropTypes.number
}

export default EffectivenessScore