import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ImageUploader from "../components/ImageUploader";

import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./challenge-submit.scss";
import Button from "../components/Button";
import ChallengesHeaderMeta from "../components/ChallengesHeaderMeta";
import ChallengesSubmitPopup from "../components/ChallengesSubmitPopup";
import {
  getCurrentSubmitChallenege,
  postCurrentChallenege,
} from "../api/routes/challenge";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import { useHistory } from "react-router-dom";
import { TOAST_ERROR_MESSAGE } from "../utils/message";

const ChallengeSubmit = (props) => {
  const history = useHistory();
  const [submitChallenge, setSubmitChallenge] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
  const openSubmissionPopup = () => {
    setShowSubmissionPopup(true);
    document.body.style.overflow = "hidden";
  };
  const closeSubmissionPopup = () => {
    setShowSubmissionPopup(false);
    document.body.style.overflow = "auto";
    navigateToScreenOrToast(responseData, history);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubmitChallenge((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (
      submitChallenge?.id &&
      submitChallenge?.screen_one &&
      submitChallenge?.screen_two &&
      submitChallenge?.notes &&
      submitChallenge?.difficulty
    ) {
      setFormValid(true);
    }
  };

  const breadcrumbsData = [
    {
      id: 1,
      link: "challenge",
      text: "Challenge",
    },
    {
      id: 2,
      link: "challenge-submit",
      text: "Submit Your Work",
    },
  ];

  useEffect(() => {
    fetchCurrentSubmitChallenege();
  }, []);

  const fetchCurrentSubmitChallenege = async () => {
    try {
      const response = await getCurrentSubmitChallenege();
      if (response && response.data) {
        setSubmitChallenge(response.data.progress_challenge);
        // setForm to Valid if all details present
        if (
          response.data &&
          response.data.progress_challenge &&
          response.data.progress_challenge.id &&
          response.data.progress_challenge.screen_one &&
          response.data.progress_challenge.screen_two &&
          response.data.progress_challenge.difficulty &&
          response.data.progress_challenge.notes
        ) {
          setFormValid(true);
        }
        navigateToScreenOrToast(response.data, history);
      }
    } catch (error) {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    }
  };

  const submitCurrentChallenge = async () => {
    const payload = {
      id: submitChallenge.id,
      screen_one: submitChallenge.screen_one,
      screen_two: submitChallenge.screen_two,
      difficulty: submitChallenge.difficulty,
      notes: submitChallenge.notes,
    };
    try {
      const response = await postCurrentChallenege(payload);
      if (response && response.data) {
        openSubmissionPopup();
        setResponseData(response.data);
      }
    } catch (error) {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    }
  };

  const getPublicUrl = (imageURL, screen) => {
    setSubmitChallenge((prev) => ({
      ...prev,
      [screen]: imageURL.fileUrl,
    }));
    if (
      submitChallenge?.id &&
      submitChallenge?.screen_one &&
      submitChallenge?.screen_two &&
      submitChallenge?.notes &&
      submitChallenge?.difficulty
    ) {
      setFormValid(true);
    }
  };
  const clearPublicUrl = (screen) => {
    setSubmitChallenge((prev) => ({
      ...prev,
      [screen]: "",
    }));
  };

  return (
    <>
      <Helmet>
        <title>ChallengeSubmit - 90 D</title>
        <meta property="og:title" content="ChallengeSubmit - 90 D" />
      </Helmet>
      <Navbar />
      <div className="container">
        <div className="challenge-submit-wrap">
          <div className="challenge-submit-top">
            <ChallengesHeaderMeta
              day={submitChallenge?.challenge?.day || 0}
              breadcrumbsData={breadcrumbsData}
            />
            <h2>Submit your design solution</h2>
          </div>
          <div className="challenge-submit-form">
            <div className="submit-form-left">
              <div className="submit-item">
                <div className="image-upload">
                  <img
                    src={
                      submitChallenge?.challenge?.design_screen ||
                      "/assets/screen-design.png"
                    }
                    alt="image"
                  />
                </div>
                <div className="item-text">Original Screen</div>
              </div>
              <div className="submit-item">
                <div className="image-upload">
                  <ImageUploader
                    getPublicUrl={getPublicUrl}
                    screen_name="screen_one"
                    screen_url={submitChallenge?.screen_one}
                    clearPublicUrl={clearPublicUrl}
                  />
                </div>
                <div className="item-text">Screen 1: Artwork Selection</div>
              </div>
              <div className="submit-item">
                <div className="image-upload">
                  <ImageUploader
                    getPublicUrl={getPublicUrl}
                    screen_name="screen_two"
                    screen_url={submitChallenge?.screen_two}
                    clearPublicUrl={clearPublicUrl}
                  />
                </div>
                <div className="item-text">Screen 2: Custom Image </div>
              </div>
            </div>
            <div className="submit-form-right">
              <div className="form-right-item">
                <h3>How was the challenge?</h3>
                <div className="form-right-data">
                  <label className="form-challenge-mode">
                    <input
                      type="radio"
                      checked={submitChallenge?.difficulty === "easy"}
                      name="difficulty"
                      value="easy"
                      onChange={handleInputChange}
                    />
                    <span>Easy</span>
                  </label>
                  <label className="form-challenge-mode">
                    <input
                      type="radio"
                      checked={submitChallenge?.difficulty === "average"}
                      name="difficulty"
                      value="average"
                      onChange={handleInputChange}
                    />
                    <span>Moderate</span>
                  </label>
                  <label className="form-challenge-mode">
                    <input
                      type="radio"
                      checked={submitChallenge?.difficulty === "difficult"}
                      name="difficulty"
                      value="difficult"
                      onChange={handleInputChange}
                    />
                    <span>Difficult</span>
                  </label>
                </div>
              </div>
              <div className="form-right-item">
                <h3>How long it took to finish?</h3>
                <div className="form-right-data">
                  <div className="form-duration">
                    <input
                      type="text"
                      placeholder="00:00"
                      maxLength="5"
                      name="time"
                      value={submitChallenge?.time || "00:00"}
                      onChange={handleInputChange}
                    />
                    <span>Hours</span>
                  </div>
                </div>
              </div>
              <div className="form-right-item">
                <h3>Notes to yourself</h3>
                <div className="form-right-data">
                  <textarea
                    placeholder="Add notes here"
                    name="notes"
                    value={submitChallenge?.notes || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-right-button">
                <Button
                  disabled={!formValid}
                  clickFunc={submitCurrentChallenge}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSubmissionPopup && (
        <ChallengesSubmitPopup closeFunc={closeSubmissionPopup} />
      )}
      <FooterMinimal />
    </>
  );
};

export default ChallengeSubmit;
