import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import "./login.scss";
import { useSelector } from "react-redux";
import { signInWithGoogle, signInWithEmailLinkApp } from "../api/auth";
import { isEmailValid } from "../utils/isEmailValid";

const Signup = (props) => {
    const history = useHistory();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    // State variable to store the email
    const [email, setEmail] = useState("");
    const [enableBtn, setEnableBtn] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);

    // Handler for email input changes
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (isEmailValid(email)) {
            setEnableBtn(true);
        } else {
            setEnableBtn(false);
        }
    };

    const logInWithGoogle = useCallback(() => {
        if (isAuthenticated) return;
        signInWithGoogle(history);
    }, []);

    const twitterSignIn = useCallback(() => {
        if (isAuthenticated) return;
        // TODO: Implement twitter sign in
    });

    // Handler for the continue button click
    const handleContinue = async () => {
        // Check is Email Valid or not
        if (!isEmailValid(email)) {
            setEmailError(true)
            return;
        }
        setLoading(true)
        await signInWithEmailLinkApp(email);
    };

    return (
        <div className="login-container">
            <Helmet>
                <title>Signup - 90 D</title>
                <meta property="og:title" content="Signup - 90 D" />
            </Helmet>
            <Navbar />
            <div className="container">
                <div className="login-wrap">
                    <h1>Get Started</h1>
                    <p>Welcome to 90D — Let's create your account.</p>
                    <div className="social-login">
                        <button
                            type="button"
                            className="login-btn"
                            onClick={logInWithGoogle}
                        >
                            <img
                                src="/icons/google-icon-200h.png"
                                alt="image"
                            />
                            <span>Continue with Google</span>
                        </button>
                        <button
                            type="button"
                            className="login-btn"
                            onClick={twitterSignIn}
                        >
                            <img
                                src="/icons/twitter-icon-200h.png"
                                alt="image"
                            />
                            <span>Continue with Twitter</span>
                        </button>
                        <button
                            type="button"
                            className="login-btn"
                        // onClick={twitterSignIn}
                        >
                            <img
                                src="/assets/ms-icon.svg"
                                alt="image"
                            />
                            <span>Continue with Microsoft</span>
                        </button>
                        <button
                            type="button"
                            className="login-btn"
                        // onClick={twitterSignIn}
                        >
                            <img
                                src="/assets/apple-icon.svg"
                                alt="image"
                            />
                            <span>Continue with Apple</span>
                        </button>
                        <div className="or-text"><span>or</span></div>

                        <div className="login-form">
                            <div className="email-ip-wrap">
                                <input
                                    type="email"
                                    placeholder="Enter your email address"
                                    autoComplete="email"
                                    className="login-ip"
                                    value={email} // Set the value of the input to the state variable
                                    onChange={handleEmailChange} // Set the onChange handler
                                    onKeyDown={handleEmailChange} // Set the onChange handler
                                    required
                                />
                                {emailError && (
                                    <span className="error">Valid email required</span>
                                )}
                            </div>

                            <div className="login-form-buttons">
                                <button type="button"
                                    className={`${loading ? 'btn-loading' : ''} login-email-btn`}
                                    onClick={handleContinue}
                                    disabled={!enableBtn}
                                >Create an account
                                </button>
                                <div className="login-new-user">Have an account? <a href="#">Login</a></div>
                            </div>
                            <div className="login-form-bottom">
                                By using 90D you agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMinimal />
        </div>
    );
};

export default Signup;
