import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import FooterMinimal from "../components/footer-minimal";
import Button from "../components/Button";
import ChallengesHeaderMeta from "../components/ChallengesHeaderMeta";
import "./challenge.scss";
import { getUserChallenge, initUserChallenge } from "../api/routes/challenge";
import { navigateToScreenOrToast } from "../utils/navigateToScreenOrToast";
import { TOAST_ERROR_MESSAGE } from "../utils/message";

const Challenge = (props) => {
  const history = useHistory();
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    fetchUserChallenege();
  }, []);

  const fetchUserChallenege = async () => {
    try {
      const response = await getUserChallenge();
      if (response && response.data) {
        setQuestion(response.data?.question);
        navigateToScreenOrToast(response.data, history);
      }
    } catch (error) {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    }
  };

  const startUserChallenege = async () => {
    const payload = {
      challenge: question?.id,
    };
    try {
      const response = await initUserChallenge(payload);
      if (response && response.data) {
        navigateToScreenOrToast(response.data, history);
      }
    } catch (error) {
      navigateToScreenOrToast(TOAST_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <Helmet>
        <title>Challenge - 90 D</title>
        <meta property="og:title" content="Challenge - 90 D" />
      </Helmet>
      <Navbar />
      <div className="container">
        <div className="challenges-wrap">
          <div className="challenges-left">
            <ChallengesHeaderMeta day={question?.day || 0} />
            <div className="challenges-left-details">
              <div className="left-detail-item">
                <h3>Today's Challenge:</h3>
                <p>{question?.short_description || ""}</p>
              </div>
              <div className="left-detail-item">
                <h3>Description:</h3>
                <p>{question?.description || ""}</p>
              </div>
              <div className="left-detail-item">
                <h3>Screens Required</h3>
                <ol>
                  <li>
                    <strong>Artwork Selection:</strong> Users can pick from a
                    set of predefined artwork options that reflect various
                    genres, moods, and themes.
                  </li>
                  <li>
                    <strong>Custom Image Uploading:</strong> Allow users to
                    upload one image from their device to use as playlist
                    artwork. No editing required.
                  </li>
                </ol>
              </div>
              <div className="left-detail-item">
                <h3>Deadline</h3>
                <p>You need to submit the work by end of the day. </p>
              </div>
            </div>
            <div className="challenges-left-bottom">
              <Button clickFunc={startUserChallenege} />
            </div>
          </div>
          <div className="challenges-right">
            <div className="challenges-right-top">
              <div className="design-logo-title">
                <img
                  src={question?.screen_logo || "/assets/logo-design.png"}
                  alt="logo"
                />
                <div className="design-title">
                  <h4>{question?.screen || ""}</h4>
                  <p>{question?.screen_short_note || ""}</p>
                </div>
              </div>
              <div className="refer-more-screens">
                <button
                  type="button"
                  onClick={() =>
                    window.open(question?.reference_link, "_blank")
                  }
                >
                  <span>Refer more screens</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                  >
                    <path
                      d="M8 1L1 8"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.15385 1H8V5.84615"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="challenges-right-screens">
              <div className="right-screen-design">
                <img
                  src={question?.design_screen || "/assets/screen-design.png"}
                  alt="logo"
                />
                <div className="screen-text">Starting screen of the flow</div>
              </div>
              <div className="right-divider">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="51"
                  height="13"
                  viewBox="0 0 51 13"
                  fill="none"
                >
                  <path
                    d="M51 6.5L41 0.726497V12.2735L51 6.5ZM0 7.5H42V5.5H0V7.5Z"
                    fill="#7A7A7A"
                  />
                </svg>
              </div>
              <div className="right-screen-info">
                <div className="right-info-wrap">
                  <div className="right-info-wrap-container">
                    <h3>Your design solution to the challenge</h3>
                    <div className="right-details-wrap">
                      <div className="right-detail-item">
                        <h4>Design Tool</h4>
                        <p>Figma/Sketch</p>
                      </div>
                      <div className="right-detail-item">
                        <h4>Screen Resolution</h4>
                        <p>
                          375 px (Width)
                          <br />
                          812 px (Height)
                        </p>
                      </div>
                      <div className="right-detail-item">
                        <h4>Output Format</h4>
                        <p>JPEG / PNG</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="screen-text">Screens to be designed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMinimal />
    </>
  );
};

export default Challenge;
