import React, { useState } from "react";
import PropTypes from "prop-types";

import "./MyChallengesListView.scss";
import MyChallengesDetails from "./MyChallengesDetails";
import MyChallengesListItem from "./MyChallengesListItem";
import { dateTimeFormat } from "../utils/dateTimeFormat";

const MyChallengesListView = (props) => {
  const currentDayList = props.myChallengeList.map((item) => item?.day);
  const currentDay = Math.max(...currentDayList);
  const [activeIndex, setActiveIndex] = useState(currentDay);
  const [activeIndexDetails, setActiveIndexDetails] = useState(null);

  const [showDetailView, setShowDetailView] = useState(false);

  const openDetailView = (day) => {
    setShowDetailView(true);
    setActiveIndex(day);
    setActiveIndexDetails(props.myChallengeList[day - 1]);
    document.body.style.overflow = "hidden";
  };

  const closeDetailView = () => {
    setShowDetailView(false);
    setActiveIndexDetails(null);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <div className="my-challenges-table">
        <table>
          <thead>
            <tr>
              <th width="5%">Day</th>
              <th width="43%">Challenge</th>
              <th width="22%">Status</th>
              <th width="15%" style={{ textAlign: "center" }}>
                Effectiveness
              </th>
              <th width="10%" style={{ textAlign: "center" }}>
                Output
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {props.myChallengeList.map((item) => (
              <MyChallengesListItem
                key={item.day} // Assign a unique key using item.day
                current={currentDay === item.day}
                day={item.day || 0}
                description={item.short_description || ""}
                status={
                  item.userchallenges[0]?.status === "in-progress"
                    ? "Ongoing"
                    : "Completed"
                }
                startDate={dateTimeFormat(
                  item.userchallenges[0]?.date_started,
                  item.userchallenges[0]?.status === "in-progress"
                    ? "Ongoing"
                    : "Completed"
                )}
                endDate={dateTimeFormat(
                  item.userchallenges[0]?.date_completed,
                  item.userchallenges[0]?.status === "in-progress"
                    ? "Ongoing"
                    : "Completed"
                )}
                score={4}
                clickFunc={() => openDetailView(item.day)}
                screens={[
                  item.design_screen || "",
                  item.userchallenges[0]?.screen_one || "",
                  item.userchallenges[0]?.screen_two || "",
                ]}
              />
            ))}

            {/* <tr className="missing-streaks">
              <td colSpan={6}>
                <span>Missed 1 day streak</span>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      {showDetailView && (
        <div className="challenge-details-popup">
          <div className="container">
            <div className="challenges-popup-back" onClick={closeDetailView}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
              >
                <path
                  d="M1 8H21"
                  stroke="#A0A0A0"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 1L21 8L14 15"
                  stroke="#A0A0A0"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {activeIndexDetails && (
              <MyChallengesDetails
                activeIndexDetails={activeIndexDetails}
                submitButtonFunc={props.submitButtonFunc}
                day={activeIndex}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

MyChallengesListView.defaultProps = {
  // rootClassName: '',
  // text: 'default text',
  myChallengeList: ["1", "2", "3", "4"],
};

MyChallengesListView.propTypes = {
  submitButtonFunc: PropTypes.func,
  myChallengeList: PropTypes.array,
};

export default MyChallengesListView;
