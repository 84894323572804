import { getApi, postApi } from "../base";

export const getUserSurvey = () => {
  const res = getApi(`/survey`);
  return res;
};

export const postUserSurvey = (payload) => {
  const res = postApi(`/survey`, payload);
  return res;
};
