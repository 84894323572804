import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import './SurveyOption.scss'

const SurveyOption = (props) => {

    return (
        <div className='survey-option-item'>
            <button
                onClick={props.clickFunc}
                className={props.selectedItem === props.btnText ? 'selected' : ''}
                type='button'>{props.btnText}</button>
        </div>
    )
}

SurveyOption.defaultProps = {
    btnText: 'Role'
}

SurveyOption.propTypes = {
    btnText: PropTypes.string,
    selectedItem: PropTypes.string,
    clickFunc: PropTypes.func
}

export default SurveyOption