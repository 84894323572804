import moment from "moment";
export const dateTimeFormat = (date, status = "Ongoing") => {
  const currentDate = moment(date);
  if (status === "Ongoing") {
    const formattedDate = currentDate.format("MMM DD, YYYY | hh:mm A");
    return formattedDate;
  }
  const formattedDate = currentDate.format("MMM DD, YYYY");
  return formattedDate;
};
