import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import LandingHero from '../components/landing-hero'
import Footer from '../components/footer'
import './how-it-works.css'
import WhyComponent from '../components/why-component'

const data1 = ['Our challenges use actual app screenshots for a true-to-life design experience.', 'You gain hands-on experience with tasks similar to those in a product company.', 'These challenges prepare you to tackle real-world scenarios in future design roles.', 'These challenges prepare you to tackle real-world scenarios in future design roles.']

const data2 = ['Revisit completed challenges anytime to see your creative solutions.', 'Observe your evolving skills with a clear record of progress.', 'Identify specific areas for improvement to focus your learning efforts.', 'Identify specific areas for improvement to focus your learning efforts.']

const data3 = ["In the design industry, your portfolio is your voice. We assist in developing a portfolio that showcases your real-world skills.", "By working on prompts from real apps, your portfolio can match the experience of someone in a product-based company.", "Display your preparedness for today's digital design demands.','Based on your performance, earn a feature on our talent showcase, opening doors to potential future opportunities."]

const data4 = ['Learn how others are solving the same challenge once you finish', 'Get diverse solution to a single design challenge', 'Take advantage of the community.', 'Find the right mentors.']


const HowItWorks = (props) => {
  return (
    <div>
      <Helmet>
        <title>HowItWorks - 90 D</title>
        <meta property="og:title" content="HowItWorks - 90 D" />
      </Helmet>
      <Navbar></Navbar>
      <div className="landing-page-container">
        <LandingHero rootClassName="landing-hero-root-class-name"></LandingHero>
        <div className="how-it-works-how-it-works">
          <div className="container">
            <div className="how-it-works-title-sec">
              <h2 className="how-it-works-title-text">
                <span>How it works</span>
              </h2>
              <p className="how-it-works-title-desc">
                Consistency is the key. We&apos;re here to help you develop
                a new, productive habit.
              </p>
            </div>
            <div className="how-it-works-group1778">
              <div className="group1778-item">
                <img
                  alt="image147781"
                  src="/assets/img1.svg"
                />
                <h3 className="how-it-works-text04">
                  <span>Get daily design briefs</span>
                </h3>
                <p className="how-it-works-text06">
                  <span>
                    <span>Let us know you better so that we can give you the right challenges to grow
                    </span>
                  </span>
                </p>
              </div>
              <div className="group1778-arrow">
                <img
                  alt="Vector147781"
                  src="/assets/arrow-1.svg"
                  className="how-it-works-vector14"
                />
              </div>
              <div className="group1778-item">
                <img
                  alt="image147781"
                  src="/assets/img2.svg"
                />
                <h3 className="how-it-works-text04">
                  <span>Submit your solutions</span>
                </h3>
                <p className="how-it-works-text06">
                  <span>
                    <span>
                      Once you&apos;ve completed your design solution, submit it. You can conduct a retrospective later.
                    </span>
                  </span>
                </p>
              </div>
              <div className="group1778-arrow">
                <img
                  alt="Vector157781"
                  src="/assets/arrow-2.svg"
                  className="how-it-works-vector14"
                />
              </div>
              <div className="group1778-item">
                <img
                  alt="image147781"
                  src="/assets/img3.svg"
                />
                <h3 className="how-it-works-text04">
                  <span>Get feedback &amp; improve</span>
                </h3>
                <p className="how-it-works-text06">
                  <span>
                    <span>Get feedback from expert designers to improve your skills and design thinking.</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="how-it-works-why-section">
          <div className="container">
            <div className="how-it-works-group1806">
              <h2 className="how-it-works-title-text">
                <span>Why you need to learn with 90D</span>
              </h2>
              <p className="how-it-works-title-desc">
                <span>
                  Streamlined for success: A simple yet powerful learning
                  companion
                </span>
              </p>
            </div>
            <div className="container">
              <WhyComponent listData={data1}
                title="Realistic Challenges" btnLink="#" image="/assets/why-image.png" />
              <WhyComponent listData={data2}
                title="Retroscope your growth" btnLink="#" image="/assets/why-image.png" />
              <WhyComponent listData={data3}
                title="Craft a Distinctive Portfolio" btnLink="#" image="/assets/why-image.png" />
              <WhyComponent listData={data4}
                title="Learn from your peers" btnLink="#" image="/assets/why-image.png" />
            </div>
          </div>
        </div>
        {/* <div className="how-it-works-group1807">
          <div className="container">
            <h2 className="how-it-works-title-text">
              <span>Loved by 35,000+ designers</span>
            </h2>
            <p className="how-it-works-title-desc">
              <span>
                Learning never ends, this would be the best investment you can
                do for your design career
              </span>
            </p>
          </div>
        </div> */}
      </div>
      <Footer></Footer>
    </div>
  )
}

export default HowItWorks
