import React from 'react'

import PropTypes from 'prop-types'

import HeroFeatures from './hero-features'
import { TypeAnimation } from 'react-type-animation';

import './landing-hero.css'

const LandingHero = (props) => {
  return (
    <div className={`landing-hero-container ${props.rootClassName} `}>
      <img
        alt={props.d90main1Alt}
        src={props.d90main1Src}
        className="landing-hero-banner"
      />
      <div className="landing-hero-group1805">
        <div className='container'>
          {/* <h1 className="landing-hero-text">90 days of design</h1> */}
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              '90d.design',
              2000, // wait 1s before replacing
              '90 days of design',
              2000
            ]}
            wrapper="span"
            className="landing-hero-text"
            speed={30}
            // deletionSpeed={90}
            repeat={Infinity}
            cursor={false}
            preRenderFirstString={true}
          />
          <p className="landing-hero-desc">
            Become a better product designer in just 90 days
          </p>
        </div>
      </div>
      <HeroFeatures />
    </div>
  )
}

LandingHero.defaultProps = {
  rootClassName: '',
  d90main1Src: '/external/d90main18197-dfb-1500w.png',
  d90main1Alt: 'D90main18197',
}

LandingHero.propTypes = {
  rootClassName: PropTypes.string,
  d90main1Src: PropTypes.string,
  d90main1Alt: PropTypes.string,
}

export default LandingHero
