import React from "react";
import PropTypes from "prop-types";

import ChallengesDate from "./ChallengesDate";
import EffectivenessScore from "./EffectivenessScore";
import ChallengeStatus from "./ChallengeStatus";

const MyChallengesListItem = (props) => {
  return (
    <tr onClick={props.clickFunc}>
      <td>
        <ChallengesDate day={props.day} currentDate={props.current} />
      </td>
      <td>{props.description}</td>
      <td>
        <ChallengeStatus
          status={props.status}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </td>
      <td>
        <EffectivenessScore score={props.score} />
      </td>
      <td>
        <div className="output-images">
          {props.screens.map((item, index) =>
            item ? (
              <img
                key={item + index}
                src={item}
                width={22}
                height={48}
                alt="screen"
              />
            ) : (
              <span
                key={item + index}
                style={{
                  width: 22,
                  height: 48,
                  backgroundColor: "#c4c4c4",
                  borderRadius: 5,
                  marginRight: 1,
                }}
              ></span>
            )
          )}
        </div>
      </td>
      <td>
        <button type="button" className="btn-action">
          <span></span>
        </button>
        {/* action dropdown TODO - design pending */}
      </td>
    </tr>
  );
};

MyChallengesListItem.defaultProps = {
  screens: [
    "/assets/screen-design.png",
    "/assets/screen-design.png",
    "/assets/screen-design.png",
  ],
};

MyChallengesListItem.propTypes = {
  day: PropTypes.number,
  current: PropTypes.bool,
  description: PropTypes.string,
  status: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  clickFunc: PropTypes.func,
  score: PropTypes.number,
  screens: PropTypes.array,
};

export default MyChallengesListItem;
