import React from "react";

import PropTypes from "prop-types";

import "./MyChallengesDetails.scss";
import ChallengesDate from "./ChallengesDate";
import ChallengeStatus from "./ChallengeStatus";
import EffectivenessScore from "./EffectivenessScore";
import Button from "./Button";
import { dateTimeFormat } from "../utils/dateTimeFormat";

const MyChallengesDetails = (props) => {
  return (
    <div className="challenges-popup-wrap">
      <div className="challenges-popup-inner">
        <div className="challenges-popup-top">
          <ChallengesDate day={props.day} />
          <div className="challenges-popup-statue">
            <ChallengeStatus
              status={
                props.activeIndexDetails?.userchallenges[0]?.status ===
                "in-progress"
                  ? "Ongoing"
                  : "Completed"
              }
              startDate={dateTimeFormat(
                props.activeIndexDetails?.userchallenges[0]?.date_started,
                props.activeIndexDetails?.userchallenges[0]?.status ===
                  "in-progress"
                  ? "Ongoing"
                  : "Completed"
              )}
              endDate={dateTimeFormat(
                props.activeIndexDetails?.userchallenges[0]?.date_completed,
                props.activeIndexDetails?.userchallenges[0]?.status ===
                  "in-progress"
                  ? "Ongoing"
                  : "Completed"
              )}
            />
          </div>
          <div className="challenges-popup-effectiveness">
            <span>Effectiveness:</span>
            <EffectivenessScore score={4} />
          </div>
          <div className="challenges-popup-actions">
            <button type="button" className="btn-action">
              <span></span>
            </button>
          </div>
        </div>
        <div className="challenges-popup-details">
          <div className="challenges-detail-item">
            <h3>Today's Challenge:</h3>
            <p>{props.activeIndexDetails?.short_description || ""}</p>
          </div>
          <div className="challenges-detail-item">
            <h3>Description:</h3>
            <p>{props.activeIndexDetails?.description || ""}</p>
          </div>
          <div className="challenges-detail-item">
            <h3>Screens Required</h3>
            <ol>
              <li>
                <strong>Artwork Selection:</strong> Users can pick from a set of
                predefined artwork options that reflect various genres, moods,
                and themes.
              </li>
              <li>
                <strong>Custom Image Uploading:</strong> Allow users to upload
                one image from their device to use as playlist artwork. No
                editing required.
              </li>
            </ol>
          </div>
        </div>
        <div className="challenges-detail-bottom">
          <div className="challenges-detail-screens">
            {/* add img tag inside <span> */}
            <span>
              {props.activeIndexDetails?.design_screen && (
                <img
                  src={props.activeIndexDetails?.design_screen}
                  alt="screen"
                />
              )}
            </span>
            <span>
              {props.activeIndexDetails?.userchallenges[0]?.screen_one && (
                <img
                  src={props.activeIndexDetails?.userchallenges[0]?.screen_one}
                  alt="screen"
                />
              )}
            </span>
            <span>
              {props.activeIndexDetails?.userchallenges[0]?.screen_two && (
                <img
                  src={props.activeIndexDetails?.userchallenges[0]?.screen_two}
                  alt="screen"
                />
              )}
            </span>
          </div>
          <Button
            disabled={
              props.activeIndexDetails?.userchallenges[0]?.status ===
              "completed"
            }
            clickFunc={props.submitButtonFunc}
          />
        </div>
      </div>
    </div>
  );
};

MyChallengesDetails.defaultProps = {};

MyChallengesDetails.propTypes = {
  activeIndexDetails: PropTypes.object,
  submitButtonFunc: PropTypes.func,
  day: PropTypes.number,
};

export default MyChallengesDetails;
