import React from 'react'

import PropTypes from 'prop-types'

import WhyPoints from './why-points'
import './why-component.css'

const WhyComponent = (props) => {
  return (
    <div className="why-component">
      <div className="why-component-image">
        <img
          alt={props.title}
          src={props.image}
        />
      </div>
      <div className="why-component-content">
        <h4 className="why-component-title">
          {props.title}
        </h4>
        {props.listData.length && (
          <ul className='why-component-list'>
            {props.listData.map((list, index) => (
              <WhyPoints
                text={list}
                key={list + index}
              ></WhyPoints>
            ))}
          </ul>
        )}
        <div className="why-component-btn">
          <a href="!#" className="why-component-link">
            {props.btnText}
          </a>
        </div>
      </div>
    </div>
  )
}

WhyComponent.defaultProps = {
  btnText: 'Try for free',
  title: 'Realistic Challenges',
  image: '',
  btnLink: '!#',
  listData: [],
}

WhyComponent.propTypes = {
  btnText: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  btnLink: PropTypes.string,
  listData: PropTypes.array,
}

export default WhyComponent
