import React from 'react'

import PropTypes from 'prop-types'

import HeroFeatureItem from './hero-feature-item'
import './hero-features.css'

const HeroFeatures = (props) => {
  return (
    <div className="hero-features-container">
      <div className="container">
        <div className="hero-features-group1772">
          <HeroFeatureItem title="Personalised for everyone" icon="/assets/hero-icon-1.svg"></HeroFeatureItem>
          <HeroFeatureItem title="Real design challenges" icon="/assets/hero-icon-2.svg"></HeroFeatureItem>
          <HeroFeatureItem title="Prepared by experts" icon="/assets/hero-icon-3.svg"></HeroFeatureItem>
          <HeroFeatureItem title="See how others solves" icon="/assets/hero-icon-4.svg"></HeroFeatureItem>
          <div className="hero-features-container1">
            <a href="#" className="hero-features-group1770">
              <span className="hero-features-text">Join for free</span>
              <div className="hero-features-group11">
                <img
                  alt="join"
                  src="/assets/join-arrow.svg"
                  className="hero-features-pasted-image"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

HeroFeatures.defaultProps = {
}

HeroFeatures.propTypes = {

}

export default HeroFeatures
