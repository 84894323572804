import React from 'react'

import PropTypes from 'prop-types'

import './Button.scss'

const Button = (props) => {
    return (
        <button type='button' className='btn_primary' disabled={props.disabled} onClick={props.clickFunc}>
            <span>{props.btnText}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                <path d="M12.1907 1L16.9999 6L12.1907 11" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 6.28857H16.2601" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    )
}

Button.defaultProps = {
    btnText: 'Submit your work',
    disabled: false,
}

Button.propTypes = {
    btnText: PropTypes.string,
    disabled: PropTypes.bool,
    clickFunc: PropTypes.func,
}

export default Button