// PrivateRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import store from "../store";
const PreAuthRoute = ({ component: Component, ...rest }) => {
  const { auth } = store.getState();
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/survey" />
        )
      }
    />
  );
};

export default PreAuthRoute;
