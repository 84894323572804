import React from 'react'

import PropTypes from 'prop-types'

import './ChallengesSubmitPopup.scss'

import Button from './Button'

const ChallengesSubmitPopup = (props) => {
    return (
        <div className='challenges-submit-popup'>
            <div className='submit-popup-container'>
                <div className='submit-popup-inner'>
                    <div className='user-avatars'>
                        <img src='/assets/90d.png' width={30} height={30} alt='img' />
                        <img src='/assets/user.png' width={30} height={30} alt='img' />
                    </div>
                    <h4 className='submit-popup-heading'>John has accepted today's challenge</h4>
                    <div className='submit-popup-meta'><span>Day 1 of 90 days challenge</span></div>

                    <div className='submit-popup-chats'>
                        <div className='submit-popup-chat-item'>
                            <div className='submit-chat-avatar'>
                                <img src='/assets/90d.png' width={40} height={40} alt='img' />

                            </div>
                            <div className='submit-chat-content'>
                                <p>Create a filter bottom drawer to select quantity(1-6), star rating,
                                    price range($286 - $2000) & some additional parameters. </p>
                            </div>
                        </div>
                        <div className='submit-popup-chat-item'>
                            <div className='submit-chat-avatar'>
                                <img src='/assets/user.png' width={40} height={40} alt='img' />

                            </div>
                            <div className='submit-chat-content'>
                                <p>Here we go!</p>
                                <div className='submit-chat-images'>
                                    <img src='/assets/screen-design.png' alt='img' />
                                    <img src='/assets/screen-design-2.png' alt='img' />
                                    <img src='/assets/screen-design-3.png' alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button btnText={`Share this everyone`} clickFunc={() => alert('shared!')} />
                <button type='button' className='submit-pop-close' onClick={props.closeFunc}>close</button>
            </div>
        </div>

    )
}

ChallengesSubmitPopup.defaultProps = {
    // rootClassName: '',
    // text: 'default text',
}

ChallengesSubmitPopup.propTypes = {
    closeFunc: PropTypes.func,
}

export default ChallengesSubmitPopup
