import React, { useState } from "react";

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import FooterMinimal from '../components/footer-minimal'
import "./user-survey-new.scss";
import Button from '../components/Button';

const UserSurveyNew = (props) => {
    const [selected, setSelected] = useState(999);

    const selectItem = (item) => {
        setSelected(item)
    }

    return (
        <div className="user-survey-container">
            <Helmet>
                <title>User survey - 90 D</title>
                <meta property="og:title" content="User survey - 90 D" />
            </Helmet>
            <Navbar />
            <div className='user-suvey-content'>
                <div className='container'>
                    <div className='survey-title'>What describes you the best?</div>
                    <p className="survey-desc">This help us to create questions that matches your expertise.</p>
                    <div className='survey-options-wrap'>
                        <div className={`survey-option ${selected === 0 ? 'active' : ''}`} onClick={() => selectItem(0)}>
                            {/* add 'active' class to above div to be selected item  */}
                            <div className='so-image'>
                                <img src='/assets/design1.png' alt='img' />
                            </div>
                            <div className='so-title'>Design Student</div>
                            <div className='so-desc'>Any course related to digital product design</div>
                        </div>
                        <div className={`survey-option ${selected === 1 ? 'active' : ''}`} onClick={() => selectItem(1)}>
                            <div className='so-image'>
                                <img src='/assets/design2.png' alt='img' />
                            </div>
                            <div className='so-title'>Design Professional</div>
                            <div className='so-desc'>Any role related that is close to product design</div>
                        </div>
                        <div className={`survey-option ${selected === 2 ? 'active' : ''}`} onClick={() => selectItem(2)}>
                            <div className='so-image'>
                                <img src='/assets/design3.png' alt='img' />
                            </div>
                            <div className='so-title'>New to design</div>
                            <div className='so-desc'>And you wish to learn product design</div>
                        </div>
                    </div>
                    <div className='survey-continue'>
                        <Button btnText={`Continue`} disabled={selected === 999} />
                    </div>
                    <div className="survey-cancel">Cancel</div>
                </div>
            </div>
            <FooterMinimal />
        </div>
    )
}

export default UserSurveyNew
